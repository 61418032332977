<template>
  <div :class="[$style.container, 'modal', { 'is-active': modalActive }]">
    <div class="modal-background" @click="internallyCloseModal" />
    <div :class="['modal-content', { [$style.product]: isProduct }]">
      <div
        ref="modalContent"
        :class="[
          $style.content,
          {
            [$style.video]: isVideo,
            [$style.notYouTube]: isVideo && isNotYouTube
          }
        ]"
      >
        <slot />
        <div v-if="isVideo && !isNotYouTube && !consentGiven" :class="$style.consent">
          <h2>{{ dictionary.consentHeading }}</h2>
          <div v-html="vendorConsentText" />
          <Button
            is-close
            :class="$style.consentButton"
            :text="consentButtonLabel"
            @close="handleContentButton"
          />
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="internallyCloseModal" />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { getVendorConsent, registerConsentListeners } from '../../globals/consentmanager'
import cmpVendorIDs from '../../globals/constants/cmpVendorIDs'
import { getMarketingConsent } from '../../globals/cookiebot'
import Button from '../Button/Button.vue'

const emit = defineEmits(['closeModal', 'openModal'])

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    default: false
  },
  isVideo: {
    type: Boolean,
    default: false
  },
  isNotYouTube: {
    type: Boolean,
    default: false
  },
  isProduct: {
    type: Boolean,
    default: false
  }
})

const vendor = cmpVendorIDs.YOUTUBE
const modalActive = ref(false)
const htmlElement = ref(null)
const consentGiven = ref(window.Cookiebot ? getMarketingConsent() : getVendorConsent(vendor.id))
const modalContent = ref(null)

const dictionary = window.dictionary
const vendorConsentText = dictionary.consentText.replaceAll("###VENDOR_NAME###", vendor.name)
const consentButtonLabel = dictionary.consentButtonLabel.replaceAll(/&apos;/g, "'")

const checkVendorConsent = () => {
  consentGiven.value = getVendorConsent(vendor.id)
  checkConsent()
}

const handleContentButton = () => {
  if (window.cmpConsentType === 'consentmanager') {
    window.__cmp?.('setVendorConsent', [vendor.id, 1], () => {
      consentGiven.value = getVendorConsent(vendor.id)
      checkConsent()
    })
  } else {
    window.Cookiebot.onaccept = () => {
      consentGiven.value = getMarketingConsent()
      checkConsent()
    }
    window.Cookiebot.show()
  }
}

const checkConsent = () => {
  const iframe = modalContent.value.querySelector('iframe')

  if (iframe && props.isVideo) {
    if (consentGiven.value && modalActive.value) {
      if (!iframe.src) {
        iframe.src = iframe.getAttribute('data-src')
        iframe.removeAttribute('data-src')
      }
    } else {
      if (iframe.src) {
        iframe.setAttribute('data-src', iframe.src)
        iframe.removeAttribute('src')
      }
    }
  }
}

const openModal = () => {
  modalActive.value = true
  htmlElement.value.classList.add('is-clipped')
  emit('openModal')
}

const closeModal = () => {
  modalActive.value = false
  htmlElement.value.classList.remove('is-clipped')
  if (props.isVideo && !props.isNotYouTube) {
    modalContent.value
      .querySelector('iframe')
      .contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
  }
}

const internallyCloseModal = () => {
  closeModal()

  window.dispatchEvent(
    new CustomEvent('modal:close', {
      detail: { props }
    })
  )

  emit('closeModal')
}

const escCloseModal = (event) => {
  if (event.keyCode === 27) {
    internallyCloseModal()
  }
}

watch(
  () => props.isModalOpen,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      newValue === true ? openModal() : closeModal()
    }
  }
)

watch(
  () => modalActive.value,
  () => {
    consentGiven.value =
      window.cmpConsentType === 'cookiebot' ? getMarketingConsent() : getVendorConsent(vendor.id)
    window.setTimeout(checkConsent, 20)
  }
)

onMounted(() => {
  registerConsentListeners(checkVendorConsent)
  window.addEventListener('keyup', escCloseModal)
  htmlElement.value = document.querySelector('html')
})

onUnmounted(() => {
  window.removeEventListener('keyup', escCloseModal)
})
</script>

<style module lang="scss">
@import './Modal.scss';
</style>
