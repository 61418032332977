const getCMPData = () => {
  return window.__cmp?.('getCMPData')
}

const getVendorConsent = (vendorId) => {
  const CMPData = getCMPData()

  if (!CMPData) {
    return false
  }

  if ('vendorConsents' in CMPData) {
    if (vendorId in CMPData.vendorConsents && CMPData.vendorConsents[vendorId]) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

// function to register consent listeners
const registerConsentListeners = (checkVendorConsent) => {
  if (window.cmpConsentType === 'consentmanager') {
    window.__cmp('addEventListener', ['consentrejected', checkVendorConsent, false], null)
    window.__cmp('addEventListener', ['consentapproved', checkVendorConsent, false], null)
    window.__cmp('addEventListener', ['consentcustom', checkVendorConsent, false], null)
  }
}

export { getVendorConsent, registerConsentListeners }
